import axios from 'axios'

const CompanyService = {

  async updateOrganization (data) {
    return axios({
      url: axios.defaults.baseURL + 'organization/',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async init () {
    return axios({
      url: axios.defaults.baseURL + 'company/',
      method: 'GET'
    })
  },

  async deleteOrganization (id) {
    return axios({
      url: axios.defaults.baseURL + 'organization/' + id,
      method: 'DELETE'
    })
  },

  async deleteCompany (id) {
    return axios({
      url: axios.defaults.baseURL + 'company/' + id,
      method: 'DELETE'
    })
  },

  async getOrganization (id) {
    return axios({
      url: axios.defaults.baseURL + 'organization/' + id,
      method: 'GET'
    })
  },

  async getAllOrganizations () {
    return axios({
      url: axios.defaults.baseURL + 'organization/',
      method: 'GET'
    })
  },

  async getCompany (id) {
    return axios({
      url: axios.defaults.baseURL + 'company/' + id,
      method: 'GET'
    })
  },

  async updateCompany (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async updateUser (data) {
    return axios({
      url: axios.defaults.baseURL + 'organization/updateUser',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async updateQBData (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/quickbooks',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async deleteQBData (companyId) {
    return axios({
      url: axios.defaults.baseURL + 'company/deleteQBData',
      method: 'POST',
      data: JSON.stringify({ company_id: companyId })
    })
  },

  async checkQBData (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/checkQBpath',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async saveUserPermission (data) {
    return axios({
      url: axios.defaults.baseURL + 'organization/userpermission',
      method: 'PATCH',
      data: JSON.stringify(data)
    })
  },

  async userInit (orgId, userId) {
    return axios({
      url: axios.defaults.baseURL + 'organization/' + orgId + '/user/' + userId,
      method: 'GET'
    })
  },

  async search (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/search',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async breakdownExport (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/breakdown/export',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async getBilling (companyId) {
    return axios({
      url: axios.defaults.baseURL + 'company/billing/get',
      method: 'POST',
      data: JSON.stringify({ company_id: +companyId })
    })
  },

  async decryptBilling (id, password) {
    return axios({
      url: axios.defaults.baseURL + 'company/billing/decrypt',
      method: 'POST',
      data: JSON.stringify({ companybilling_id: +id, decryptpassword: password })
    })
  },

  async saveBilling (data) {
    return axios({
      url: axios.defaults.baseURL + 'company/billing/save',
      method: 'POST',
      data: JSON.stringify(data)
    })
  },

  async deleteBilling (id) {
    return axios({
      url: axios.defaults.baseURL + 'company/billing/delete',
      method: 'POST',
      data: JSON.stringify({ companybilling_id: +id })
    })
  }

}

export default CompanyService
